<template>
  <div class="main">
    <data-loader v-bind:isLoading="isLoading"
                 innerText="Подождите, данные загружаются"
                 v-bind:isSpinner="true" />
    <div v-if="!isLoading">
      <div class="calculation-log">
        <div class="d-flex calculation-log-head">
          <h2 class="mb-0" v-on:click="openCalcLog">Детали расчетов</h2>
          <div class="form-inline justify-content-end">
            <div class="form-group mb-0">
              <label for="" class="mr-2">Выберите дату расчета</label>
              <select v-if="calcDates"
                      v-bind:class="selectClass"
                      v-model="currentInvestmentObjectId">
                <option v-for="(el, index) in calcDates"
                        v-bind:key="el.calculationDate"
                        v-bind:value="el.investmentObjectDataId">
                  {{ optionVal(el, index) }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <pre v-html="pageData" v-if="pageData"></pre>
        <p v-if="!pageData && !isLoading">
          Расчет от {{ formatTime(currentCalculationDate) }}. Невозможно получить журнал расчета
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import * as internalAdmin from '@/models/internalAdmin';
import DataLoader from '@/components/DataLoader.vue';
import openCalcLogShiva from './../lib/openCalcLogShiva';
import { formatNumber } from '@/lib/formatNumber.js';
import moment from 'moment';

const momentFormat = 'DD.MM.YYYY HH:mm';

export default {
  data() {
    return {
      pageData: null,
      calcDates: [],
      currentInvestmentObjectId: null,
      currentCalculationDate: null,
      isCurrentPriceLargerThanPrev: [],
      contentIsloaded: false,
      isLoading: true
    };
  },
  components: {
    DataLoader,
  },
  computed: {
    // isLoading() {
    //   return this.$apollo.queries.calculationLogs.loading || this.$apollo.queries.calculationLogObject.loading;
    // },
    query() {
      return Object.assign({}, this.$route.query);
    },
    selectClass() {
      return [
        'form-control',
        {
          'highlight': this.isCurrentPriceLargerThanPrev.some(el => el).length != 0
        }
      ]
    }
  },
  methods: {
    openCalcLog(e) {
      openCalcLogShiva(e, this.currentInvestmentObjectId);
    },
    formatTime(val) {
      const utcTime = moment.utc(val);
      return val ? moment(utcTime).local().format(momentFormat) : '';
    },
    optionVal(el, index) {
      const calcDate = this.formatTime(el.calculationDate);
      const priceMarket = `${formatNumber(el.priceMarket)} ₽`;
      const star = this.isCurrentPriceLargerThanPrev[index] ? '*' : '';
      return `${calcDate} | ${priceMarket} ${star}`
    }
  },
  apollo: {
    calculationLogs: {
      query() {
      return internalAdmin.investmentObjectCalculationLogs;
      },
      update(data) {},
      result({ data, loading }) {
        if (!loading) {
          this.calcDates = data.calculationLogs;
          this.calcDates.forEach((el, ind, arr) => {
            if (ind == arr.length - 1) this.isCurrentPriceLargerThanPrev.push(false);
            else {
              this.isCurrentPriceLargerThanPrev.push(Math.abs(el.priceMarket / arr[ind + 1].priceMarket - 1) > 0.05);
            }
          });
          this.currentInvestmentObjectId = this.$route.query.investment_object_data_id || this.calcDates[0].investmentObjectDataId;
          this.currentCalculationDate = this.calcDates[0].calculationDate;
        }
      },
      variables() {
        return {
          investmentObjectId: this.$route.params.id,
        }
      }
    },
    calculationLogObject: {
      query() {
        return internalAdmin.investmentObjectCalculationLogObject;
      },
      update(data) {},
      result({ data, loading }) {
        if (!loading) {
          this.isLoading = false;
          this.pageData = data.calculationLogObject.value;
        }
      },
      variables() {
        return {
          investmentObjectId: this.$route.params.id,
          investmentObjectDataId: this.currentInvestmentObjectId
        }
      },
      skip () {
        return !this.currentInvestmentObjectId
      },
    }
  },
  watch: {
    currentInvestmentObjectId(val) {
      this.query.investment_object_data_id = val;
      this.$router.replace({ path: this.$route.path, query: { ...this.query } }).catch(()=>{});
    }
  },
  mounted() {
    this.contentIsloaded = true;
  }
};
</script>

<style lang="scss" scoped>
.calculation-log {
  padding: 1rem;
}

.calculation-log-head {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 2rem;
}

.form-control {
  &.highlight {
    background: rgba($warning,0.2);
  }
}
</style>
